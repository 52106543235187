
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { AbilityBuilder, Ability } from "@casl/ability";
import { useAbility } from "@casl/vue";

export default defineComponent({
  name: "two-factor",
});
